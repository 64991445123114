import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Tag from './tag'
import { DateSpan } from './dateSpan'
import ImgCache from '@utils/ImgCache'

const PostCard = ({ node }: {
  node: {
    excerpt: string
    fields: {
      slug: string
    }
    frontmatter: {
      date: string
      title: string
      description: string
      tags: string[]
    }
  }
}) => {
  const fm = node.frontmatter
  const title = fm.title || node.fields.slug
  const slug = node.fields.slug
  const tags = fm.tags
  return (
    <div key={title} className='col-md-6 col-sm-12 col-padding mb-2'>
      <GlassDiv className='blog-entry'>
        <Link className={fm.image ? 'img' : 'd-none'} to={`/post${slug}`}>
          {fm.image ? (
            <CardImg
              className='w-100 img-fluid lazyload blur-up'
              data-src={ImgCache(fm.image)}
              alt={title}
              src='/media/image-placeholder.webp'
            ></CardImg>
          ) : null}
        </Link>
        <div className='desc'>
          {/* title and link */}
          <CardHeader>
            <Link title={title} to={`/post${slug}`}>
              {title}
            </Link>
          </CardHeader>
          {/* post time */}
          <DateSpan>
            {fm.date}
          </DateSpan>
          {/* excerpt */}
          <div className='excerpt mb-1' dangerouslySetInnerHTML={{ __html: node.excerpt }}></div>
          <div className='author d-flex align-items-center'>
            <div className='info'>
              {tags ? (
                <div className='tags pt-1'>
                  {tags.map((tag: string) => (
                    <Tag key={tag} tag={tag} />
                  ))}
                </div>
              ) : null}
            </div>
            <div className='ml-3'></div>
          </div>
        </div>
      </GlassDiv>
    </div>
  );
}

const GlassDiv = styled.div`
  background: rgba( 255, 255, 255, 0.40 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 12.0px );
  -webkit-backdrop-filter: blur( 12.0px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  color: #fff;
`;

const CardImg = styled.img`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  max-height: 350px;
  object-fit: contain;
`;

const CardHeader = styled.h2`
  margin-top: 0;

  a {
    color: #fff;
  }
`;

export default PostCard
