import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Tag from './tag';

const TagWidget = () => {
  const postWithTags = useStaticQuery(graphql`{
    allMarkdownRemark {
      nodes {
        id
        frontmatter {
          tags
        }
      }
    }
  }`);
  let tags: string[] =  postWithTags.allMarkdownRemark.nodes.reduce((previousTags: any, currentPost: any) => {
    const tagPerPost = currentPost && currentPost.frontmatter && currentPost.frontmatter.tags ? currentPost.frontmatter.tags : []
    return previousTags.concat(tagPerPost)
  }, [])
  tags = Array.from(new Set(tags))
  return <div className="tag-widget">
    <h3>Tags</h3>
    <div>
      {tags.map((tag: string, index: number) => <Tag key={index} tag={tag} />)}
    </div>
  </div>
}

export default TagWidget
