import React, { FunctionComponent } from 'react'
import { Link } from 'gatsby'
import { TagIcon } from '@primer/octicons-react'
import styled from 'styled-components'

interface TagProps {
  tag: string
}

const TagWrapper = styled.span`
  color: #00e7ff;
`

const Tag: FunctionComponent<TagProps> = ({ tag }) => {
  return <Link
    to={ `/tag/${tag.toLowerCase()}` }
    title={ tag }
    >
    <TagWrapper className="tag mr-1">
      <TagIcon size="small" />{` `}
      {tag}
    </TagWrapper>
  </Link>
}

export default Tag
