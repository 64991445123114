import * as React from "react"
import { Link, graphql } from "gatsby"
import { Index } from 'lunr'
import { globalHistory } from '@reach/router'

import Layout from "@components/layout"
import SEO from "@components/seo"
import PostCard from "@components/postCard"
import TagWidget from "@components/tagWidget"
import SearchWidget from "@components/searchWidget"

interface Props {
  readonly data: PageQueryData
}

const BlogIndex: React.FunctionComponent<Props> = ({ data }) => {
  const location = () => typeof window === 'object' ? window.location : null
  const [searchQuery, setSearchQuery] = React.useState('')

  const siteTitle = data.site.siteMetadata?.title || `Title`
  const [posts, setPost] = React.useState<PostNode[]>([]);

  const loadSearchResults = () => {
    const query = new URLSearchParams(location().search).get('search');
    // console.log('location', query);

    // console.log('data.LunrIndex', data.LunrIndex);
    if (!query) {
      setPost(data.allMarkdownRemark.nodes);
      return;
    }

    setSearchQuery(query || '');
    const { store } = data.LunrIndex;
    const index = Index.load(data.LunrIndex.index);
    const results = index.search(query).map(({ ref }) => {
      return {
        excerpt: store[ref].excerpt,
        fields: {
          slug: ref,
        },
        frontmatter: {
          title: store[ref].title
        }
      };
    });
    // console.log(results);
    setPost(results || data.allMarkdownRemark.nodes);
  }

  React.useEffect(() => {
    loadSearchResults();

    const globalHistorySub = globalHistory.listen(() => {
      const q = new URLSearchParams(location().search).get('search');
      // console.log(q);
      loadSearchResults();
    });

    return () => {
      globalHistorySub();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title={siteTitle}>
      <SEO title="All posts" />

      <div id="main">
          <div className="container-fluid">
              <div className="row d-flex">
                  <div className="col-xl-8 px-md-5 py-5">
                      <div className="row row-bottom-padded-md">
                          <div className="narrow-content py-0">
                              <div className="row row-bottom-padded-md">
                                { posts.map((el, index) => <PostCard key={index} node={el}></PostCard>) }

                                { posts.length == 0 ? <h1>No post found!</h1> : null }
                              </div>
                          </div>
                      </div>

                      <div className="row">
                          <div className="col">
                              <div className="block-27">
                                  {/* { pagination } */}
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className="col-xl-4 px-md-5 py-5">
                      <div className="sidebar-box">
                        <SearchWidget
                          searchQuery={searchQuery}
                          setSearchQuery={setSearchQuery}
                        ></SearchWidget>
                      </div>
                      <div className="sidebar-box">
                          <TagWidget></TagWidget>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

interface PostNode {
  excerpt: string
  fields: {
    slug: string
  }
  frontmatter: {
    date: string
    title: string
    image?: string
    description: string
    tags: string[]
  }
}

interface LurnStore {
  slug: string
  title: string
  excerpt: string
}

interface PageQueryData {
  site: {
    siteMetadata: {
      title: string
    }
  }
  // LunrIndex: {
  //   index: string[]
  //   store: LurnStore[]
  // }
  LunrIndex: any
  allMarkdownRemark: {
    nodes: PostNode[]
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    LunrIndex
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          image
          description
          tags
        }
      }
    }
  }
`
