import React from 'react';
import { navigate } from '@reach/router'
import { SearchIcon } from '@primer/octicons-react'

interface SearchWidgetProps {
  searchQuery: string
  setSearchQuery: Function
  placeHolder?: string
}

const SearchWidget: React.FunctionComponent = ({ searchQuery, setSearchQuery, placeHolder = 'Search posts' }) => {
  const ENTER_KEY = 13;
  const [value , setValue] = React.useState(searchQuery || '');

  return (
  <form action="/" method="GET" autoComplete="off" onSubmit={(evt) => {
    evt.preventDefault()
    if (value) {
      navigate(`/?search=${encodeURIComponent(value)}`)
    } else {
      navigate(`/`)
    }
  }}>
    <div className="input-group">
      <input
        value={value}
        placeholder={placeHolder}
        onChange={(e) => setValue(e.target.value)}
        onKeyUp={(e) => e.key===ENTER_KEY && setSearchQuery(value)}
        name="search"
        className="form-control"
        type="text"
      />
      <div className="input-group-append">
        <button className="btn btn-light" type="submit">
          <SearchIcon size={16} />
        </button>
      </div>
    </div>
  </form>);
};

export default SearchWidget
